// Override default variables before the import
$body-bg: #fff;
$link-color: #3da199; 
$link-hover-color: #00635e; 
// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

.cursor-pointer {
  cursor: pointer;
}

html {
  height:100%;
}

body {
  height:100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  margin-bottom: 0
}